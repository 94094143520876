<template>
  <VorgangTable
    v-if="vorgaenge"
    :items="vorgaenge"
    :isLoading="isLoading"
    :isOutlined="true"
    :displayFieldsByKey="[
      'id',
      'reisezeitraum',
      'kunde',
      'npx',
      'brutto',
      'releaseprocessActions1',
      'releaseprocessActions2',
      'releaseprocessActions3',
      'releaseProzessState',
    ]"
    :dokumenteCheckRequired="dokumenteCheckRequired"
    :passdatenCheckRequired="passdatenCheckRequired"
    releaseprocessActions1Label="Teilnehmernamen"
    releaseprocessActions2Label="Passdaten"
    releaseprocessActions3Label="Dokumente"
  >
    <template v-slot:releaseprocessActions1="data">
      <b-form-select
        :disabled="!isReiseterminInReleasePruefung || !(data.isVorgangBuchung || data.isStornierteBuchung)"
        size="sm"
        v-model="data.vorgang.teilnehmerGeprueft"
        @change="onChangeData(data)"
      >
        <b-form-select-option :value="'NichtGeprueft'">
          {{ $t('REISETERMINE.RELEASEPROZESS.NICHTGEPRUEFT') }}
        </b-form-select-option>
        <b-form-select-option :value="'InArbeit'">
          {{ $t('REISETERMINE.RELEASEPROZESS.INARBEIT') }}
        </b-form-select-option>
        <b-form-select-option :value="'GeprueftVorhandenKorrekt'">
          {{ $t('REISETERMINE.RELEASEPROZESS.GEPRUEFTVORHANDEN') }}
        </b-form-select-option>
      </b-form-select>
    </template>
    <template v-slot:releaseprocessActions2="data">
      <b-form-select
        v-if="passdatenCheckRequired"
        :disabled="!isReiseterminInReleasePruefung || !(data.isVorgangBuchung || data.isStornierteBuchung)"
        size="sm"
        v-model="data.vorgang.passdatenGeprueft"
        @change="onChangeData(data)"
      >
        <b-form-select-option :value="'NichtGeprueft'">
          {{ $t('REISETERMINE.RELEASEPROZESS.NICHTGEPRUEFT') }}
        </b-form-select-option>
        <b-form-select-option :value="'InArbeit'">
          {{ $t('REISETERMINE.RELEASEPROZESS.INARBEIT') }}
        </b-form-select-option>
        <b-form-select-option :value="'GeprueftVorhandenKorrekt'">
          {{ $t('REISETERMINE.RELEASEPROZESS.GEPRUEFTVORHANDEN') }}
        </b-form-select-option>
      </b-form-select>
      <div v-else>Nicht notwendig</div>
    </template>
    <template v-slot:releaseprocessActions3="data">
      <b-form-select
        v-if="dokumenteCheckRequired"
        :disabled="!isReiseterminInReleasePruefung || !(data.isVorgangBuchung || data.isStornierteBuchung)"
        size="sm"
        v-model="data.vorgang.dokumenteGeprueft"
        @change="onChangeData(data)"
      >
        <b-form-select-option :value="'NichtGeprueft'">
          {{ $t('REISETERMINE.RELEASEPROZESS.NICHTGEPRUEFT') }}
        </b-form-select-option>
        <b-form-select-option :value="'InArbeit'">
          {{ $t('REISETERMINE.RELEASEPROZESS.INARBEIT') }}
        </b-form-select-option>
        <b-form-select-option :value="'GeprueftVorhandenKorrekt'">
          {{ $t('REISETERMINE.RELEASEPROZESS.GEPRUEFTVORHANDEN') }}
        </b-form-select-option>
      </b-form-select>
      <div v-else>Nicht notwendig</div>
    </template>

    <template v-slot:vorgangDetails="data">
      <div class="bg-gray-100 border-bottom border-dark ml-10 mb-2">
        <b-table
          small
          bordered
          table-class="table table-standard mb-0"
          id="vorgaengeDetailsTableTeilnehmer"
          thead-tr-class="th-no-padding-top"
          :items="data.teilnehmer"
          :fields="[
            { key: 'name', label: 'Name', tdClass: 'cw-30' },
            { key: 'geburtsdatum', label: 'Geburtsdatum', tdClass: 'cw-5' },
            { key: 'nationalitaet', label: 'Nationalität', tdClass: 'cw-5' },
            { key: 'passdaten', class: 'passdaten-col', tdClass: 'cw-60' },
          ]"
        >
          <template #cell(name)="row">
            <b-icon-person-fill></b-icon-person-fill>
            <b>{{ row.item.name }}</b>
            <PassCheckIcon
              v-if="konfiguration"
              :provided="row.item.name != null"
              :required="false"
            ></PassCheckIcon>
          </template>
          <template #cell(geburtsdatum)="row">
            <i
              v-if="isBirthdayDuringTravelPeriod(row.item.geburtsdatum)"
              class="fas fa-birthday-cake text-success"
            />
            {{ row.item.geburtsdatum | date }}
            <PassCheckIcon
              v-if="konfiguration"
              :provided="row.item.geburtsdatum != null"
              :required="konfiguration.geburtsdatumRequired"
            ></PassCheckIcon>
          </template>
          <template #cell(nationalitaet)="row">
            {{ row.item.nationalitaet }}
            <PassCheckIcon
              v-if="konfiguration"
              :provided="row.item.nationalitaet != null"
              :required="konfiguration.nationalitaetRequired"
            ></PassCheckIcon>
          </template>
          <template #cell(passdaten)="row">
            <PassDatenTable
              :reiseterminStartdatum="reiseterminStartdatum"
              :teilnehmer="row.item"
              :konfiguration="konfiguration"
            ></PassDatenTable>
          </template>
        </b-table>
      </div>
    </template>
  </VorgangTable>
</template>

<script>
import PassCheckIcon from '@/components/vorgaenge/passdaten-check-icon.vue';
import VorgangTable from '@/components/vorgaenge/vorgang-table.vue';
import PassDatenTable from '@/components/vorgaenge/passdaten-table.vue';
import { UPDATE_VORGANG_RELEASEPROZESS } from '../../../core/vorgaenge/stores/vorgaenge.module';

const moment = require('moment');

export default {
  components: { VorgangTable, PassDatenTable, PassCheckIcon },
  props: {
    isLoading: { type: Boolean },
    reiseterminStartdatum: { type: [String, Object] },
    reiseterminEnddatum: { type: [String, Object] },
    reiseterminId: { type: Number },
    vorgaenge: { type: Array },
    passdatenCheckRequired: { type: Boolean },
    dokumenteCheckRequired: { type: Boolean },
    isReiseterminInReleasePruefung: { type: Boolean },
    konfiguration: { type: Object },
    reiseterminObj: { type: Object },
  },
  methods: {
    onChangeData(data) {
      const { vorgang } = data;
      const {
        id,
        gebuchteReiseleistungListenversandGeprueft,
        dokumenteGeprueft,
        passdatenGeprueft,
        teilnehmerGeprueft,
        fluegePassend,
        transferplanungErledigt,
        transfergruppe,
      } = vorgang;
      const paramsReq = [
        {
          id,
          gebuchteReiseleistungListenversandGeprueft,
          dokumenteGeprueft,
          passdatenGeprueft,
          teilnehmerGeprueft,
          fluegePassend,
          transferplanungErledigt,
          transfergruppe,
        },
      ];
      this.$store.dispatch(UPDATE_VORGANG_RELEASEPROZESS, paramsReq).then(() => {
        this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
        this.$emit('workflow-updated');
      });
    },
    parseDate(date) {
      return date ? this.$options.filters.date(date) : '-';
    },
    isBirthdayDuringTravelPeriod(birthday) {
      const start = moment(this.reiseterminStartdatum);
      const end = moment(this.reiseterminEnddatum);
      const birth = moment(birthday);
      birth.set({ year: start.year() });
      return birth.isBetween(start, end, 'days', '[]');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.passdaten-col) {
  width: 50rem;
}
:deep(.th-no-padding-top > th) {
  padding-top: 0px;
}
:deep(.th-no-padding-top > th > div) {
  color: #b5b5c3;
}
:deep(.cw-5) {
  width: 5%;
}
:deep(.cw-10) {
  width: 10%;
}
:deep(.cw-30) {
  width: 30%;
}
:deep(.cw-60) {
  width: 60%;
}
</style>
